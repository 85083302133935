:root {
  --tl-width: 720px;
  --emoji-size: 2em;
  --avatar-size: 46px;
  --radius: 12px;
  --radius-round: 24px;
  --panel-radius: var(--radius);
  --hover-color: rgba(170, 170, 170, 7%);
  --elevated-color: rgba(150, 150, 200, 10%);
  --elevated-tint: rgba(200, 200, 240, 7%);
  --border-color: rgba(120, 120, 200, 20%);
  --border-color-2: #787878;
  --shadow: 0 10px 40px -10px rgba(0, 0, 0, 15%);
  --shadow-low: 0 8px 24px -16px rgba(0, 0, 0, 20%);
  --shadow-med: 0 8px 60px -30px rgba(0, 0, 0, 10%);
  --column-shadow: 0 8px 24px 12px rgba(0, 0, 0, 2%);
  --background-border-color: var(--border-color);
}
@media (width <= 889px) {
  :root {
    --panel-radius: 0px;
  }
}

.layout-multiple-columns {
  --panel-radius: 0px;
}

body {
  font-display: swap !important;
}

body:not(.admin)::before {
  content: '';
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 6%);
  z-index: -1;
}

p {
  line-height: 1.5;
}

input {
  text-align: start;
}

.button--block {
  font-weight: bold;
}

.unhandled-link span,
.mention span {
  text-decoration: none !important;
}

.unhandled-link:not(:focus, :hover) span,
.mention:not(:focus, :hover) span {
  text-decoration: underline !important;
}

input,
.input-copy,
select,
textarea,
.compose-form__upload-thumbnail,
.button,
:not(.notification__filter-bar) > button:not(.column-header__button),
video,
.privacy-dropdown__dropdown,
.react-toggle-track,
.reply-indicator,
.compose-form__warning {
  border-radius: var(--radius);
}

button:focus-visible,
.focusable:focus-visible,
a:focus-visible,
.media-gallery__item-thumbnail:focus-visible {
  box-shadow: inset 0 0 0 2px #dc7b18;
  outline: 2px #dc7b18 solid;
  outline-offset: -2px;
}

:not(.radio-button__input, span) {
  border-color: var(--border-color) !important;
}

.nothing-here,
.column-inline-form,
.scrollable,
.detailed-status__action-bar,
.column-back-button,
.column-header__collapsible.collapsed,
.column-header__collapsible-inner,
.audio-player,
.search__input {
  border: 0 !important;
}

.account__section-headline,
.notification__filter-bar,
.column-header {
  border-inline: 0;
}

.account__section-headline,
.notification__filter-bar,
.column > .scrollable {
  background: none;
}

.account__avatar,
#profile_page_avatar,
.account__avatar-composite,
.account-card__title__avatar img {
  border-radius: 30%;
  flex: none;
}

:not(body, .scrollable)::-webkit-scrollbar {
  width: 6px;
  margin-block: 10px;
}

:not(body, .scrollable)::-webkit-scrollbar-track {
  background: none;
}

:not(body, .scrollable)::-webkit-scrollbar-thumb {
  border-radius: 100px;
  transition: background-color 0.2s;
}

:not(body, .scrollable, :hover)::-webkit-scrollbar-thumb {
  background: none;
  padding-block: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  body:not(.reduce-motion) .load-more,
  body:not(.reduce-motion) .setting-toggle,
  body:not(.reduce-motion) .column-header__back-button,
  body:not(.reduce-motion) .column-back-button,
  body:not(.reduce-motion) .trends__item,
  body:not(.reduce-motion) .story,
  body:not(.reduce-motion) .account__avatar,
  body:not(.reduce-motion) .button,
  body:not(.reduce-motion) .media-gallery__item,
  body:not(.reduce-motion) .column-link,
  body:not(.reduce-motion) select,
  body:not(.reduce-motion) .status-card,
  body:not(.reduce-motion) .audio-player,
  body:not(.reduce-motion) .account {
    transition:
      transform 0.4s cubic-bezier(0, 0, 0, 3),
      background 0.2s,
      opacity 0.2s !important;
  }

  body:not(.reduce-motion) .load-more:active,
  body:not(.reduce-motion) .setting-toggle:active,
  body:not(.reduce-motion) .column-header__back-button:active,
  body:not(.reduce-motion) .column-back-button:active,
  body:not(.reduce-motion) .trends__item:active,
  body:not(.reduce-motion) .story:active,
  body:not(.reduce-motion) .account__avatar:active,
  body:not(.reduce-motion) .button:active,
  body:not(.reduce-motion) .media-gallery__item:active,
  body:not(.reduce-motion) .column-link:active,
  body:not(.reduce-motion) select:active,
  body:not(.reduce-motion) .status-card:active,
  body:not(.reduce-motion) .audio-player:active,
  body:not(.reduce-motion) .account:active,
  body:not(.reduce-motion) .load-more:focus-visible,
  body:not(.reduce-motion) .setting-toggle:focus-visible,
  body:not(.reduce-motion) .column-header__back-button:focus-visible,
  body:not(.reduce-motion) .column-back-button:focus-visible,
  body:not(.reduce-motion) .trends__item:focus-visible,
  body:not(.reduce-motion) .story:focus-visible,
  body:not(.reduce-motion) .account__avatar:focus-visible,
  body:not(.reduce-motion) .button:focus-visible,
  body:not(.reduce-motion) .media-gallery__item:focus-visible,
  body:not(.reduce-motion) .column-link:focus-visible,
  body:not(.reduce-motion) select:focus-visible,
  body:not(.reduce-motion) .status-card:focus-visible,
  body:not(.reduce-motion) .audio-player:focus-visible,
  body:not(.reduce-motion) .account:focus-visible {
    transform: scale(0.99);
    transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
  }

  body:not(.reduce-motion) .column-header__button,
  body:not(.reduce-motion)
    .column-header__buttons
    > .column-header__back-button,
  body:not(.reduce-motion) .react-toggle-track,
  body:not(.reduce-motion) .icon-button,
  body:not(.reduce-motion) .floating-action-button {
    transition:
      transform 0.4s cubic-bezier(0, 0, 0, 4),
      background 0.2s !important;
  }

  body:not(.reduce-motion) .column-header__button:active,
  body:not(.reduce-motion)
    .column-header__buttons
    > .column-header__back-button:active,
  body:not(.reduce-motion) .react-toggle-track:active,
  body:not(.reduce-motion) .icon-button:active,
  body:not(.reduce-motion) .floating-action-button:active {
    transform: scale(0.95);
    transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
  }

  body:not(.reduce-motion) .status__content__spoiler-link span {
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0, 0, 0, 4) !important;
  }

  body:not(.reduce-motion) .status__content__spoiler-link:active span {
    transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
    transform: translateY(1px);
  }

  .reduce-motion * {
    animation-duration: 0s !important;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }

    30% {
      transform: scale(0.99);
      opacity: 1;
    }

    60% {
      transform: scale(1.005);
      opacity: 1;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes slide-up {
    from {
      transform: translateY(20px);
    }
  }
  @keyframes slide-up-fade {
    from {
      transform: translateY(20px);
      filter: opacity(0);
    }
  }
  @keyframes slide-down-fade {
    from {
      transform: translateY(-20px);
      filter: opacity(0);
    }
  }
  @keyframes slide-up-big {
    from {
      transform: translateY(50vh);
    }
  }
  @keyframes fade-up {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
  }
  @keyframes scale-in {
    from {
      transform: scale(0.98);
      opacity: 0;
    }
  }
  @keyframes fade-left {
    from {
      transform: translateX(20px) opacity(0);
    }
  }
  @keyframes rainbow {
    to {
      filter: hue-rotate(360deg);
    }
  }
}

.columns-area__panels {
  --top: 5px;

  gap: 0;
}
@media (width >= 1175px) {
  .columns-area__panels {
    padding-inline: 10px;
    padding-top: var(--top);
    box-sizing: border-box;
    transition: padding 0.4s;

    --top: 20px;
  }
}
@media (width >= 1320px) {
  .columns-area__panels {
    --top: 30px;
  }
}

.compose-panel {
  overflow-y: auto;
  margin-top: calc(0px - var(--top));
  padding-top: var(--top);
  padding-bottom: 20px;
  padding-inline: 10px;
  box-sizing: border-box;
  max-height: unset !important;
  height: 100%;
}

.compose-panel > * {
  padding-inline: 0;
}

.compose-panel > .navigation-bar {
  padding-top: 0 !important;
}

.compose-panel .search,
.drawer .search {
  margin-bottom: 25px;
}

.compose-form__uploads {
  padding: 0;
  margin-block: 0 !important;
  margin-inline: 12px;
  width: unset;
}

.search {
  border-radius: var(--radius);
  margin-inline: -5px;
}

.search label {
  box-sizing: border-box;
}

.search input {
  border-radius: var(--radius-round) !important;
}

.search .search__icon > i {
  margin-inline: 5px;
}

.search__popout {
  border-radius: var(--radius);
  animation: scale-in 0.2s;
  box-shadow: var(--shadow-low);
  margin-top: 10px;
  margin-inline: 4px;
  width: calc(100% - 8px);
}

.navigation-bar .icon-button {
  width: auto !important;
  height: auto !important;
  padding: 8px;
}

.compose-form {
  min-height: unset;
  overflow: unset;
  gap: 15px;
  flex: 1 0 auto !important;
}

.compose-form > * {
  margin: 0 !important;
}

.compose-form > [aria-hidden='true'] {
  display: none;
}

.compose-form > .navigation-bar {
  margin-top: 10px;
}

.compose-form .reply-indicator {
  position: relative;
  transition: min-height 0.1s;
}

.compose-form .reply-indicator__display-name {
  padding: 0;
}

.compose-form .spoiler-input__border {
  display: none;
}

.compose-form #cw-spoiler-input {
  padding-inline: 12px;
}

.compose-form .compose-form__autosuggest-wrapper,
.compose-form .autosuggest-textarea__textarea {
  border-radius: var(--radius) var(--radius) 0 0 !important;
  border-bottom: 0;
}

.compose-form .compose-form__buttons-wrapper {
  border-radius: 0 0 var(--radius) var(--radius) !important;
}

.compose-form .compose-form__publish-button-wrapper {
  overflow: visible !important;
  max-width: 100%;
  padding: 0;
}

.compose-form .compose-form__upload__actions {
  z-index: 5;
  position: relative;
}

.compose-form .compose-form__upload__actions button {
  background: none;
}

.compose-form .compose-form__upload__thumbnail {
  display: flex;
  flex-direction: column;
}

.compose-form .compose-form__upload__warning {
  position: relative;
  flex-grow: 1;
  display: flex;
}

.compose-form .compose-form__upload__warning button {
  margin-top: auto;
}

.compose-form .compose-form__upload__warning button.active {
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 75%);
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 1.1em;
  color: inherit;
  transition:
    background 0.2s,
    transform 0.2s cubic-bezier(0, 0, 0, 1) !important;
}

.compose-form .compose-form__upload__warning button.active svg {
  height: 1.2em;
  width: 1.2em;
}

.compose-form .compose-form__upload__warning button.active:hover,
.compose-form .compose-form__upload__warning button.active:focus {
  background: rgba(20, 20, 20, 75%);
}

.compose-form__highlightable {
  border-radius: var(--radius);
  overflow: visible !important;
}

.compose-form__highlightable #cw-spoiler-input {
  border-radius: 0 !important;
}

.compose-form__highlightable textarea {
  background: none !important;
}

.compose-form__highlightable .compose-form__dropdowns {
  max-width: calc(100% - 7ch);
}

.compose-form__highlightable .compose-form__actions {
  position: relative;
}

.compose-form__highlightable .compose-form__buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0;
  flex-grow: 9999;
}

.compose-form__highlightable .compose-form__buttons * {
  display: flex;
  flex-grow: 1;
}

.compose-form__highlightable .compose-form__buttons label {
  display: none;
}

.compose-form__highlightable .compose-form__buttons button {
  flex-grow: 1;
  padding: 5px;
}

.compose-form__highlightable .compose-form__submit button {
  padding: 8px 16px;
}

.compose-form__highlightable .character-counter {
  position: absolute;
  inset-inline-end: 0;
  bottom: calc(100% + 12px);
  padding: 4px;
  font-size: 13px;
}

.server-banner .server-banner__hero {
  width: 100%;
  border-radius: var(--radius) var(--radius) 0 0;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
}

.server-banner .server-banner__description {
  border: 1px solid var(--border-color);
  padding: 14px 12px;
  margin-top: -20px;
  line-height: 1.5;
  border-radius: 0 0 var(--radius) var(--radius);
}

.server-banner .server-banner__meta {
  flex-direction: column;
}

.server-banner .server-banner__meta .server-banner__meta__column {
  width: unset;
  overflow: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.server-banner .server-banner__meta .server-banner__meta__column h4 {
  margin: 0;
  font-size: 0.9em !important;
  width: 100%;
}

.server-banner .server-banner__meta .server-banner__meta__column .account {
  margin-inline: -10px;
  padding: 10px !important;
  width: 100%;
}

.server-banner
  .server-banner__meta
  .server-banner__meta__column
  .account::after {
  content: unset !important;
}

.server-banner
  .server-banner__meta
  .server-banner__meta__column
  .server-banner__number,
.server-banner
  .server-banner__meta
  .server-banner__meta__column
  .server-banner__number-label {
  font-size: 1.1em;
  line-height: 2;
  margin-inline-end: 0.5em;
}

.server-banner
  .server-banner__meta
  .server-banner__meta__column
  .server-banner__number-label {
  font-weight: 400 !important;
}

.navigation-panel__sign-in-banner {
  margin-inline: 5px;
  position: relative;
}

.link-footer {
  margin-top: 20px;
}

.link-footer > p:last-child {
  margin-bottom: 0;
}

.columns-area {
  box-shadow: var(--column-shadow);
  padding: 0;
  overflow: visible;
}

.columns-area__panels__main {
  overflow: visible !important;
  contain: inline-size style !important;
  transition:
    max-width 0.2s cubic-bezier(0, 0, 0, 1.1),
    margin 0.2s cubic-bezier(0, 0, 0, 1.1);
}
@media (width >= 1175px) {
  .columns-area__panels__main {
    width: 0;
    flex-grow: 1;
    margin-inline: 10px;
    max-width: var(--tl-width) !important;
  }
}
@media (width >= 1320px) {
  .columns-area__panels__main {
    margin: 0 20px;
  }
}
@media (width >= 890px) {
  .columns-area__panels__main {
    display: grid;
    grid-template-columns: 100%;
  }

  .columns-area__panels__main .column,
  .columns-area__panels__main .columns-area {
    grid-column: 1;
    overflow: clip !important;
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }

  .columns-area__panels__main .column > :first-child,
  .columns-area__panels__main .columns-area > :first-child {
    border-radius: var(--radius) var(--radius) 0 0 !important;
  }
}

.columns-area__panels__main > div {
  grid-row: 1;
}

.column {
  background: var(--background-color);
  overflow: clip;
}

.column::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--elevated-tint);
  pointer-events: none;
}
@media (width >= 890px) {
  .layout-single-column .scrollable > [tabindex='-1']:first-child {
    margin-top: 10px;
  }

  .layout-single-column .item-list > article:first-of-type {
    margin-top: 10px;
  }

  .layout-single-column .item-list > article::after {
    inset-inline: calc(var(--radius) + 10px);
  }

  .layout-single-column .load-more,
  .layout-single-column .trends__item,
  .layout-single-column .focusable,
  .layout-single-column .entry,
  .layout-single-column .statuses-grid__item .detailed-status,
  .layout-single-column .story,
  .layout-single-column
    .scrollable
    :not(.focusable)
    > .account:not(.account--minimal),
  .layout-single-column .timeline-hint,
  .layout-single-column .notification-request {
    margin-inline: 10px;
    max-width: calc(100% - 20px);
  }
}

.scrollable {
  padding-bottom: 40vh !important;
}

.empty-column-indicator,
.error-column {
  background: none;
}

.dismissable-banner {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 20px;
  margin: 0;
  border-radius: 0;
  border: 0;
  padding: 25px;
}

.dismissable-banner > div {
  padding: 0;
}

.dismissable-banner button {
  padding: 16px;
  margin: -16px -14px;
}

.tabs-bar__wrapper {
  grid-column: 2;
  border: 0 !important;
  padding-top: 0;
  transition:
    margin 0.2s cubic-bezier(0, 0, 0, 1.1),
    top 0.4s;
}
@media (width >= 890px) {
  .tabs-bar__wrapper {
    margin-top: -100vh;
  }
}

.column-header,
.column-inline-form {
  font-weight: 600;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.column-header ~ .scrollable,
.column-inline-form ~ .scrollable {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.column-header__title {
  display: inline;
}

.column-header__title svg {
  vertical-align: -0.4em;
}

.announcements,
.column-header__collapsible:not(.collapsed) {
  flex-direction: column-reverse;
  align-items: flex-start;
  border: 0;
  animation: slide-down-fade 0.3s backwards cubic-bezier(0, 1, 0, 1.2);
}

.column-header__collapsible {
  transition: none;
  background: var(--surface-background-color);
  overflow-y: auto !important;
}

.tabs-bar__wrapper .collapsed {
  display: none;
}

.announcements {
  background: var(--surface-background-color);
}

.announcements__container {
  width: 100% !important;
}

.announcements__mastodon {
  display: block !important;
  z-index: -1;
  position: relative;
}

.announcements__pagination {
  bottom: unset;
  padding-block: 0;
  display: flex;
  align-items: center;
}

.column-header__wrapper > :not(.column-header, .collapsed) {
  border-top: 2px solid var(--background-color) !important;
  border: 0;
}

.column-header {
  overflow: hidden;
}

.column-header > button {
  z-index: 2;
}

.column-header__buttons {
  isolation: isolate;
}

.column-header__buttons button {
  transition:
    background 0.2s,
    transform 0.3s !important;
  position: relative;
  border-radius: 100px !important;
  min-width: 40px;
  margin: 5px;
  margin-inline-start: 0;
  font-size: 0.9em;
  padding-inline: 10px;
}

.column-header__buttons button:not(.active) {
  background: var(--elevated-color) !important;
  z-index: 2;
}

.column-header__buttons button svg {
  margin: 0;
}

.column-header__buttons button span {
  display: none;
}

.column-header__buttons button::before {
  content: '';
  position: absolute;
  inset: -20px -800px;
  transform: scale(0);
  transform-origin: bottom center;
  background: var(--surface-background-color);
  z-index: -1;
  border-radius: 100px;
  pointer-events: none;
  opacity: 0;
  transition:
    transform 0.3s,
    opacity 0.3s;
}
@media (prefers-reduced-motion) {
  .column-header__buttons button::before {
    transition: none !important;
  }
}

.column-header__buttons button.active::before {
  transform: scale(1, 5);
  opacity: 1;
  transition:
    transform 0.3s,
    opacity 0.1s;
}
@media (width >= 890px) {
  .tabs-bar__wrapper {
    inset-inline: unset !important;
    height: 50px !important;
    top: 0;
    top: var(--top) !important;
    width: 285px;
    border-radius: var(--radius) var(--radius) !important;
    box-shadow: 0 12px 12px -12px rgba(0, 0, 0, 10%);
    margin-inline-start: 20px;
  }

  .tabs-bar__wrapper .column-header__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: var(--radius);
    overflow: hidden;
  }

  .tabs-bar__wrapper .column-header__wrapper > div {
    background: var(--surface-background-color);
  }

  .tabs-bar__wrapper .column-header {
    background: none !important;
    overflow: hidden;
    border: 0;
  }
}
@media (width >= 890px) and (width <= 1319px) {
  .tabs-bar__wrapper {
    margin-inline-start: 10px;
  }
}
@media (width >= 890px) and (width <= 1174px) {
  .tabs-bar__wrapper {
    width: 265px;
    top: 10px !important;
  }
}
@media (width >= 890px) {
  .column-back-button--slim {
    margin-left: auto !important;
    order: -1;
  }

  .column-back-button--slim > .column-back-button {
    margin-top: 0 !important;
    top: unset !important;
  }
}

.column-settings__row,
.column-settings__hashtags {
  gap: 0;
}

.column-settings h3 {
  font-size: 1em;
  margin-bottom: 8px;
}

.column-select__control {
  border-radius: var(--radius);
}

.local-settings__page__item,
.glitch-setting-text.glitch-setting-text,
.setting-toggle,
.app-form__toggle {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  position: relative;
  padding: 0.7em;
  background: var(--elevated-color);
  margin-block: 0 2px !important;
  overflow: hidden;
}

.local-settings__page__item:first-of-type,
.glitch-setting-text.glitch-setting-text:first-of-type,
.setting-toggle:first-of-type,
.app-form__toggle:first-of-type {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.local-settings__page__item:last-of-type,
.glitch-setting-text.glitch-setting-text:last-of-type,
.setting-toggle:last-of-type,
.app-form__toggle:last-of-type {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}

.local-settings__page__item label,
.glitch-setting-text.glitch-setting-text label,
.setting-toggle label,
.app-form__toggle label,
.local-settings__page__item legend,
.glitch-setting-text.glitch-setting-text legend,
.setting-toggle legend,
.app-form__toggle legend {
  padding-block: 2px !important;
}

.local-settings__page__item label span::before,
.glitch-setting-text.glitch-setting-text label span::before,
.setting-toggle label span::before,
.app-form__toggle label span::before {
  content: '';
  position: absolute;
  inset: -900px;
}

.local-settings__page__item .react-toggle,
.glitch-setting-text.glitch-setting-text .react-toggle,
.setting-toggle .react-toggle,
.app-form__toggle .react-toggle {
  order: 2;
}

.local-settings__page__item .setting-toggle__label,
.glitch-setting-text.glitch-setting-text .setting-toggle__label,
.setting-toggle .setting-toggle__label,
.app-form__toggle .setting-toggle__label {
  margin-bottom: 0 !important;
  flex-grow: 1;
  width: 0;
}

.local-settings__page__item::before,
.glitch-setting-text.glitch-setting-text::before,
.setting-toggle::before,
.app-form__toggle::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--hover-color);
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.local-settings__page__item:hover::before,
.glitch-setting-text.glitch-setting-text:hover::before,
.setting-toggle:hover::before,
.app-form__toggle:hover::before,
.local-settings__page__item:focus-within::before,
.glitch-setting-text.glitch-setting-text:focus-within::before,
.setting-toggle:focus-within::before,
.app-form__toggle:focus-within::before {
  opacity: 1;
}
@media (width >= 890px) and (width <= 1174px) {
  .column-back-button--slim > .column-back-button {
    margin-top: -55px !important;
    top: unset !important;
  }
}

.navigation-panel.navigation-panel {
  box-sizing: border-box;
  height: calc(100vh - var(--top) - 50px + var(--radius));
  padding-bottom: 10px;
  margin: 0;
  border: 0;
  margin-top: calc(0px - var(--radius));
  padding-top: calc(10px + var(--radius));
  overflow: hidden auto;
}

.navigation-panel.navigation-panel hr {
  display: none;
}

.navigation-panel.navigation-panel .navigation-panel__menu {
  display: contents;
}
@media (width >= 1175px) {
  .navigation-panel.navigation-panel {
    padding-top: calc(var(--radius) + 10px);
    margin-top: calc(50px - var(--radius));
  }

  .navigation-panel.navigation-panel .navigation-panel__logo {
    margin: 0;
  }

  .navigation-panel.navigation-panel .navigation-panel__logo .column-link,
  .navigation-panel.navigation-panel .navigation-panel__logo hr {
    display: none !important;
  }

  .navigation-panel.navigation-panel .switch-to-advanced {
    border-radius: var(--radius);
    margin-top: 0;
  }

  .navigation-panel.navigation-panel [href='/settings/preferences'] {
    display: none !important;
  }
}

.column-link {
  border: 0;
  gap: 0;
}

.icon-with-badge__badge {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 0.3em !important;
  width: 2em;
  height: 2em;
  min-width: max-content;
  border-radius: 2em;
  font-weight: 600;
  font-size: 0.6em !important;
  top: -10px;
  box-sizing: border-box;
}

.column-header__back-button,
.column-back-button > svg,
.column-link__icon,
.column-header > button .column-header__icon {
  margin-inline-end: 0.7em;
  font-size: 16px !important;
  padding-right: 0 !important;
}
@media (width >= 890px) {
  .column-link {
    flex-grow: 100 !important;
    display: flex !important;
    align-items: center !important;
    align-content: center;
    max-height: 3em;
    min-height: 2.7em !important;
    padding-block: 0;
    border-radius: 100px;
    position: relative;
    box-sizing: border-box;
    opacity: 0.9;
    overflow: hidden;
    background: none !important;
  }

  .column-link::before {
    content: '' !important;
    position: absolute;
    border-radius: 100px;
    width: unset !important;
    height: unset !important;
    inset: 0 !important;
    opacity: 0 !important;
    background-color: rgba(150, 150, 150, 10%);
    transition: opacity 0.2s;
  }

  .column-link.active {
    opacity: 1 !important;
    font-weight: 600;
  }

  .column-link:hover::before,
  .column-link:focus::before {
    opacity: 1 !important;
  }

  .column-link[href='/lists'] + div hr {
    display: none;
  }

  .column-link[href='/lists'] + div .column-link {
    margin-bottom: 2px;
  }

  .column-link[href='/lists'] + div .column-link i {
    opacity: 0.2;
  }

  .navigation-panel.navigation-panel .getting-started__trends {
    display: unset !important;
  }
}

.navigation-panel.navigation-panel .trends__item {
  margin: 0 !important;
}

.scrollable
  > div:first-child
  > [tabindex='-1']:last-child
  .status__wrapper::after {
  content: unset;
}

.focusable,
.entry,
.statuses-grid__item .detailed-status,
.trends__item,
.story,
.scrollable :not(.focusable) > .account:not(.account--minimal),
.timeline-hint,
.notification-request {
  overflow: hidden;
  contain: paint inline-size;
  position: relative;
  border-radius: var(--panel-radius);
  border: 0;
}

.focusable.focusable,
.entry.focusable,
.statuses-grid__item .detailed-status.focusable,
.trends__item.focusable,
.story.focusable,
.scrollable :not(.focusable) > .account:not(.account--minimal).focusable,
.timeline-hint.focusable,
.notification-request.focusable {
  background: none;
}
@media (pointer: fine) {
  .focusable::before,
  .entry::before,
  .statuses-grid__item .detailed-status::before,
  .trends__item::before,
  .story::before,
  .scrollable :not(.focusable) > .account:not(.account--minimal)::before,
  .timeline-hint::before,
  .notification-request::before {
    content: '';
    position: absolute;
    inset: 0 !important;
    height: unset !important;
    width: unset !important;
    pointer-events: none;
    transition: background-color 0.2s;
  }

  .focusable:hover::before,
  .entry:hover::before,
  .statuses-grid__item .detailed-status:hover::before,
  .trends__item:hover::before,
  .story:hover::before,
  .scrollable :not(.focusable) > .account:not(.account--minimal):hover::before,
  .timeline-hint:hover::before,
  .notification-request:hover::before,
  .focusable:focus-within::before,
  .entry:focus-within::before,
  .statuses-grid__item .detailed-status:focus-within::before,
  .trends__item:focus-within::before,
  .story:focus-within::before,
  .scrollable
    :not(.focusable)
    > .account:not(.account--minimal):focus-within::before,
  .timeline-hint:focus-within::before,
  .notification-request:focus-within::before {
    background-color: var(--hover-color);
  }
}

.status:not(.status--first-in-thread) {
  border: 0;
}

.detailed-status,
.status {
  padding: 16px;
}

.status__info .account__avatar,
.status__info .status__avatar {
  max-width: var(--avatar-size) !important;
  max-height: var(--avatar-size) !important;
}

.status__line {
  left: calc(16px + (var(--avatar-size) / 2));
}

.status__prepend + .status:not(.status-direct) {
  padding-top: 5px;
}
@media (width <= 450px) {
  .status--in-thread {
    --avatar-size: 34px;
  }

  .status--in-thread .status__info ~ * {
    margin-inline-start: calc(var(--avatar-size) + 10px);
    width: calc(100% - (var(--avatar-size) + 10px));
  }
}

.status__content {
  text-align: unset !important;
  line-height: 1.5;
}

.status__content.status__content--with-spoiler {
  overflow: visible;
}

.status__content.status__content--with-spoiler > p {
  margin-inline: -100px;
  padding-inline: 100px;
  overflow: hidden;
}

.status__content.status__content--with-spoiler > p:first-child {
  margin-bottom: 0;
}

.status__content p:empty {
  max-height: 0;
}

.status__content picture {
  display: contents;
}

.status__content .custom-emoji {
  display: inline-block;
  height: var(--emoji-size) !important;
  min-width: var(--emoji-size) !important;
  width: auto !important;
  margin: -0.2ex 0 0.2ex;
}
@media (prefers-reduced-motion: no-preference) {
  .custom-emoji {
    transition: transform 1s cubic-bezier(0, 0.7, 0, 1);
  }

  .custom-emoji:hover {
    transform: scale(1.7);
    transition: transform 0.4s cubic-bezier(0, 0.7, 0, 1);
  }
}

.status__content ~ [style*='aspect-ratio'] {
  max-height: 80vh;
}

.detailed-status__wrapper-direct .status__content,
.status-direct .status__content,
.status__wrapper-direct .status__content,
.conversation .status__content {
  position: relative !important;
  background: var(--elevated-color);
  padding: 12px 14px;
  border-radius: var(--radius-round);
  border-top-left-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 0;
  overflow: hidden !important;
  max-width: max-content;
}

.detailed-status__wrapper-direct .status__content .media-gallery,
.status-direct .status__content .media-gallery,
.status__wrapper-direct .status__content .media-gallery,
.conversation .status__content .media-gallery {
  width: 999px;
  max-width: 100% !important;
}

.status__wrapper-direct:not(.detailed-status__wrapper-direct) .status__prepend {
  position: absolute;
  contain: strict;
}

.detailed-status {
  border: 0;
  padding-bottom: 4px;
}

.detailed-status__wrapper,
.detailed-status {
  box-shadow: var(--shadow);
}

.detailed-status__wrapper .status__content,
.detailed-status .status__content {
  min-height: unset !important;
}

.detailed-status__wrapper {
  isolation: isolate;
  background: none;
}

.detailed-status__wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--elevated-tint) !important;
  pointer-events: none;
  z-index: -1;
}

.detailed-status__wrapper .detailed-status {
  box-shadow: none;
}

.detailed-status__meta {
  margin-top: 14px;
  line-height: 2;
}

.detailed-status__meta > * {
  display: inline-flex;
  border: 0 !important;
  padding: 0 !important;
  margin-inline-end: 8px;
}

.detailed-status__meta > *:not(:last-child)::after {
  content: '·';
}

.media-gallery,
.video-player,
.status-card.horizontal.interactive,
.status-card,
.audio-player,
.picture-in-picture-placeholder {
  box-shadow: var(--shadow-low);
  border-radius: var(--radius) !important;
  margin-block: 10px;
  animation: scale-in 0.4s;
  max-width: unset !important;
}

.media-gallery:has(.spoiler-button:not(.spoiler-button--minified)) {
  height: 150px !important;
  aspect-ratio: unset !important;
}

.media-gallery__item {
  border-radius: 0;
  outline: none;
}

.spoiler-button--minified button {
  padding: 6px !important;
  background: rgba(0, 0, 0, 20%) !important;
}

.spoiler-button--minified button::after {
  content: '';
  position: absolute;
  inset: -50px;
}

.spoiler-button--minified button:hover {
  background: rgba(0, 0, 0, 40%) !important;
}

.spoiler-button--minified .icon {
  width: 18px;
  height: 18px;
}

.status-card {
  align-items: stretch;
  gap: 0;
}

.status-card:not(.horizontal) {
  border: 1px solid var(--border-color) !important;
}

.status-card:not(.expanded) .status-card__image {
  overflow: hidden;
}

.status-card:not(.expanded) .status-card__image img {
  border-radius: 0;
}

.status-card:not(.interactive) .status-card__image {
  position: relative;
  aspect-ratio: unset !important;
}

.status-card__content {
  margin-block: auto;
  padding: 15px;
}

.status-card__host {
  font-size: 0.85em;
  line-height: 1.5;
  margin: 0;
}

.status-card__title {
  font-size: 1em;
  margin-top: 0.2em;
  margin-bottom: 0;
  line-height: 1.4;
}

.status-card__description {
  line-height: 1.4 !important;
  margin: 0;
}
@supports (-webkit-line-clamp: 8) {
  .status-card__description {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    white-space: unset;
  }
}

.status-card__author {
  margin-top: 0.4em;
  font-size: 0.85em;
}

.status-card:hover {
  background-color: var(--hover-color);
}

.more-from-author {
  background: none;
  border: 0;
  padding-top: 0;
  border-radius: var(--radius);
}

.audio-player .video-player__seek {
  margin: var(--radius);
}

.hashtag-bar {
  margin-top: 10px;
}

.hashtag-bar a,
.hashtag-bar button {
  color: var(--accent, #8c8dff);
  transition: opacity 0.2s;
  padding: 5px 10px;
}

.hashtag-bar a {
  position: relative;
  border-radius: var(--radius-round);
  background: var(--elevated-color);
}

.hashtag-bar a::after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--elevated-color);
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.2s;
}

.hashtag-bar a:hover,
.hashtag-bar a:focus {
  opacity: 1;
}

.hashtag-bar a:hover::after,
.hashtag-bar a:focus::after {
  opacity: 1;
}

.hashtag-bar button {
  padding-block: 0;
}

.status__action-bar {
  flex-wrap: wrap;
  margin-top: 0.4em;
  margin-bottom: -6px;
  gap: 0;
  margin-inline-start: -8px;
}

.status__action-bar__button-wrapper {
  flex-grow: 1;
  max-width: 55px;
  min-width: max-content;
}

.status__action-bar * {
  display: flex !important;
  justify-content: center !important;
  flex-grow: 1 !important;
}

.status__action-bar .icon-button {
  margin: 0;
}

.status__action-bar .icon-button::before {
  content: '';
  position: absolute;
  inset: -0.5em;
}

.status__action-bar,
.detailed-status__action-bar,
.picture-in-picture__footer {
  position: relative;
  z-index: 2;
  justify-content: unset;
}

.status__action-bar .icon-button,
.detailed-status__action-bar .icon-button,
.picture-in-picture__footer .icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.4em !important;
  border-radius: var(--radius);
  position: relative;
}

.status__action-bar .icon-button .icon-button__counter,
.detailed-status__action-bar .icon-button .icon-button__counter,
.picture-in-picture__footer .icon-button .icon-button__counter {
  width: auto !important;
}

.status__action-bar .icon-button--with-counter,
.detailed-status__action-bar .icon-button--with-counter,
.picture-in-picture__footer .icon-button--with-counter {
  padding-inline: 0.7em !important;
}

.detailed-status__action-bar,
.picture-in-picture__footer {
  padding-inline: 15px !important;
  gap: 0;
}

.detailed-status__action-bar .icon-button,
.picture-in-picture__footer .icon-button {
  flex-grow: 1 !important;
}

.detailed-status__action-bar div,
.picture-in-picture__footer div {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.item-list > article > div {
  position: relative;
}

.item-list > article > div::after {
  content: '';
  position: absolute;
  bottom: 0;
  inset-inline: 0;
  border-top: 1px solid var(--border-color);
  pointer-events: none;
}
@media (width >= 890px) {
  .layout-single-column .item-list > article > div::after {
    inset-inline: calc(var(--radius) + 10px);
  }
}

.account__wrapper {
  line-height: 1.5;
}

.account__contents {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 0 10px;
}

.display-name {
  margin-bottom: 1px !important;
}

.account:not(.account--minimal) .display-name__account {
  display: block;
  width: 300px;
}

.account__details {
  font-size: 0.9em;
  opacity: 0.8;
  width: 100px;
  flex-grow: 1;
  text-align: end;
  align-items: center;
  line-height: 1.2;
}

.account__details:has(.verified-badge) > :not(.verified-badge) {
  display: none;
}

.account__wrapper button:not(:hover, :focus) {
  background: var(--elevated-color);
  color: inherit;
}

.notification-ungrouped {
  padding: 0;
}

.notification-ungrouped__header {
  padding-top: 16px;
  margin-bottom: 0;
}

.notification-ungrouped .status__wrapper {
  margin-inline: 0;
  max-width: unset;
}

.notification-ungrouped .status__wrapper::before,
.notification-ungrouped .status__wrapper::after {
  content: unset;
}

.notification-ungrouped .status {
  padding: 16px;
}

.notification-group {
  padding: 16px;
}

.notification-group__main__additional-content {
  display: none;
}

.trends__item,
.story,
.account-card {
  animation: slide-up-fade backwards 0.4s 0.24s cubic-bezier(0, 1, 1, 1);
  border-radius: var(--radius);
}

.trends__item:nth-child(1),
.story:nth-child(1),
.account-card:nth-child(1) {
  animation-delay: 0.04s;
}

.trends__item:nth-child(2),
.story:nth-child(2),
.account-card:nth-child(2) {
  animation-delay: 0.08s;
}

.trends__item:nth-child(3),
.story:nth-child(3),
.account-card:nth-child(3) {
  animation-delay: 0.12s;
}

.trends__item:nth-child(4),
.story:nth-child(4),
.account-card:nth-child(4) {
  animation-delay: 0.16s;
}

.trends__item:nth-child(5),
.story:nth-child(5),
.account-card:nth-child(5) {
  animation-delay: 0.2s;
}

.trends__item:nth-child(6),
.story:nth-child(6),
.account-card:nth-child(6) {
  animation-delay: 0.24s;
}

.explore__links {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.explore__links > .dismissable-banner {
  margin: -10px;
  margin-bottom: 10px;
}

.explore__links .story {
  margin-inline: 0 !important;
}

.trends__item {
  display: flex !important;
  margin-inline: 0 !important;
}

.trends__item__name a::before {
  content: '';
  position: absolute;
  inset: 0;
}

.trends__item__current {
  display: none;
}

.trends__item__sparkline {
  overflow: visible !important;
  pointer-events: none;
}

.trends__item__sparkline svg {
  overflow: visible !important;
}

.trends__item__sparkline path:first-child {
  filter: blur(8px);
}

.trends__item__sparkline path:last-child {
  mask: linear-gradient(to left, #000, #000, transparent);
  -webkit-mask: linear-gradient(to left, #000, #000, transparent);
}

.rtl .trends__item__sparkline {
  transform: scaleX(-1);
}

.explore__links .trends__item {
  margin: 7.5px !important;
  padding: 25px !important;
  box-shadow: var(--shadow-med);
  width: 200px;
  background: var(--elevated-color);
  flex-grow: 1;
}

.explore__links .trends__item::after {
  content: unset !important;
}

.explore__links .trends__item a {
  font-size: 1.4em;
  line-height: 1.7em;
}

.explore__links .trends__item a::before {
  content: '';
  position: absolute;
  inset: 0;
}

.explore__links .trends__item .trends__item__sparkline {
  height: 100%;
}

.explore__links .trends__item .trends__item__sparkline svg {
  height: 100%;
  float: right;
  overflow: visible !important;
  position: relative;
}

.explore__links .trends__item .trends__item__sparkline svg path {
  display: unset !important;
  transition: transform 1s;
}

.explore__links .trends__item .trends__item__sparkline svg path:first-child {
  transform-origin: center;
}

.explore__links .trends__item:hover svg path:first-child,
.explore__links .trends__item:focus-within svg path:first-child {
  transform: scale(2);
}

.explore__links .story {
  width: 100%;
  margin: 0;
}

.account__header {
  overflow: visible;
}

.follow-request-banner {
  margin-bottom: -100px;
  padding-bottom: 120px;
}

.account__header__image {
  height: 0;
  padding-bottom: 35%;
  border-radius: var(--panel-radius) var(--panel-radius) 0 0;
  position: sticky;
  top: calc(0px - var(--panel-radius));
  overflow: clip;
}

.account__header__image img {
  position: absolute;
}

.account__header__image .account__header__info {
  position: absolute;
  z-index: 2;
  height: 100%;
}

.account__header__image .account__header__info > span {
  position: sticky;
  top: var(--radius);
}

.account__header__bar {
  position: relative;
  z-index: 2;
  border: 0;
  padding-inline: 20px;
  border-radius: var(--radius) var(--radius) 0 0;
  margin-top: calc(0px - var(--radius)) !important;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
  isolation: isolate;
}
@media (width <= 890px) {
  .account__header__bar {
    padding-inline: 15px;
  }
}

.account__header__bar::before {
  content: '';
  background: var(--elevated-tint);
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.account__header__bar::after {
  content: '';
  position: absolute;
  inset-inline: 0;
  height: 95px;
  background: inherit;
  z-index: -1;
  border-radius: var(--radius);
  mask: linear-gradient(to bottom, transparent, #000);
}

.account__header__tabs {
  overflow: visible !important;
  align-items: flex-end;
  padding: 0;
  height: unset !important;
  margin-top: -55px !important;
}

.account__header__tabs::before {
  content: '';
  position: absolute;
  top: -55px;
  inset-inline: 0;
  height: 150px;
  backdrop-filter: blur(40px);
  filter: brightness(1.1);
  pointer-events: none;
  opacity: 0.7;
  z-index: -2;
  clip-path: inset(55px 0 0 0 round var(--radius));
}

.account__header__tabs ~ div {
  z-index: 2;
}

.account__header__bar .avatar {
  margin-inline-start: 0 !important;
  overflow: visible !important;
  position: relative;
  margin-top: 20px;
}

.account__header__bar .avatar .account-role {
  position: absolute;
  bottom: 0;
  left: 110%;
  border-radius: var(--radius);
}

.account__header__bar .account__avatar {
  border: 0;
  box-shadow: var(--shadow);
  background: none;
  background-size: cover !important;
}

.account__header__tabs__name {
  margin-bottom: 0;
  padding: 0;
  margin-top: 16px;
}

.account__header__tabs__name h1 {
  display: flex;
  flex-wrap: wrap;
  white-space: unset;
  gap: 0 0.4em;
  font-weight: 600;
}

.account__header__extra {
  margin-top: 8px;
}

.account__header__fields,
.account__header__account-note {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  background: none;
  border-radius: var(--radius) !important;
  overflow: hidden;
  max-width: max-content;
  border: 0 !important;
}

.account__header__fields dl {
  display: inline;
  border-radius: 0;
  border: 0 !important;
  padding: 8px 12px !important;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
}

.account__header__fields dl:not(.verified) {
  background-color: var(--elevated-color);
}

.account__header__fields dl dt {
  all: unset !important;
  color: unset !important;
  opacity: 0.9 !important;
}

.account__header__fields dl dd {
  padding: 0;
  white-space: unset;
  max-height: unset;
  text-align: unset;
}

.account__header__fields dl dd > span > a:first-child:last-child::after,
.account__header__fields dl dd .h-card:first-child:last-child a::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--hover-color);
  opacity: 0;
  transition: opacity 0.2s;
}

.account__header__fields dl dd > span > a:first-child:last-child:hover::after,
.account__header__fields dl dd .h-card:first-child:last-child a:hover::after,
.account__header__fields dl dd > span > a:first-child:last-child:focus::after,
.account__header__fields dl dd .h-card:first-child:last-child a:focus::after {
  opacity: 1;
}

.account__header__fields dl dd.verified {
  overflow: visible !important;
  border: 0;
  background: none;
}

.account__header__fields dl dd.verified a::before,
.account__header__fields dl dd.verified a::after {
  content: '';
  position: absolute;
  inset: 0;
  background: currentcolor;
  opacity: 0.2;
}

.account__header__fields dl dd.verified a::after {
  background: linear-gradient(20deg, currentcolor, transparent) !important;
  opacity: 0.2 !important;
  z-index: -2;
}

.account__header__account-note {
  position: relative;
  font-size: 0.9em;
  max-width: unset;
  padding: 0.5em 10px !important;
  margin-block: -5px 10px;
  margin-inline: -10px !important;
  border-radius: var(--radius) !important;
}

.account__header__account-note::after {
  content: '';
  position: absolute;
  bottom: 0;
  inset-inline: 10px;
  border-top: 1px solid var(--border-color);
  transition: opacity 0.2s;
}

.account__header__account-note:focus-within::after {
  opacity: 0;
}

.account__header__account-note label {
  z-index: 2;
  margin: 0;
  pointer-events: none;
  font-size: inherit;
}

.account__header__account-note textarea {
  margin: -100px !important;
  padding: 100px !important;
  padding-inline-end: 0.7em !important;
  margin-inline-end: -0.7em !important;
  box-sizing: content-box;
  width: 100%;
  font-size: inherit;
  transition: background 0.2s;
}

.account__header__account-note textarea::placeholder {
  font-weight: 600;
}

.account-gallery__container {
  border-radius: var(--radius);
  overflow: clip;
  padding: 0;
  margin: 4px;
  gap: 4px;
}

.account-authorize__wrapper {
  background: var(--elevated-color);
  border-radius: var(--radius);
  overflow: hidden;
  flex-grow: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
@media (width <= 890px) {
  .account-authorize__wrapper {
    margin-inline: 10px;
  }
}

.layout-multiple-columns .account-authorize__wrapper {
  margin-inline: 10px;
}

.account-authorize__wrapper .account-authorize {
  padding: 20px 15px 10px;
}

.account-authorize__wrapper .detailed-status__display-name {
  margin-bottom: 10px !important;
}

.account-authorize__wrapper .account--panel {
  margin-top: auto;
  border-bottom: 0;
  padding-inline: 15px;
  gap: 10px;
  background: none;
}

.account-authorize__wrapper br {
  display: block;
}

.account-authorize__wrapper p {
  margin-bottom: 0.2em;
}

.account-authorize__wrapper
  .account--panel__button:first-child
  .icon-button:not(:hover, :focus) {
  background: var(--elevated-color);
}

.account-authorize__wrapper .icon-button {
  width: 100% !important;
  padding: 10px;
  height: unset !important;
}

.about__meta {
  border-radius: var(--radius);
}

.account--minimal {
  max-width: 100%;
}

.about__section {
  margin: 30px -20px;
  padding-inline: 20px;
  contain: inline-size paint;
}

.about__section.active .about__section__title {
  margin-inline: -20px;
  border-radius: 0;
  border-inline: 0;
  border-bottom: 0;
}

.about__section__title {
  position: sticky;
  top: -1px;
  z-index: 2;
  background: var(--background-color-tint);
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  overflow: hidden;
  transition:
    margin 0.2s cubic-bezier(0, 1, 0, 1),
    border-radius 0.2s;
}

.about__section__title::after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--elevated-tint);
  backdrop-filter: blur(10px);
  z-index: -1;
}

.about__section__body {
  border: 0;
  padding: 0;
  animation: slide-down-fade 0.4s 0.1s backwards cubic-bezier(0, 1, 0, 1.2);
}

.explore__search-results {
  border: 0;
}

.search-results__section__header {
  margin: 0 0 10px;
  color: unset;
  background: none;
  padding-inline: 25px;
  font-weight: 600;
}

.search-results__section {
  border: 0;
  margin-bottom: 20px;
}

.admin-wrapper .content__heading {
  margin-bottom: 2em;
}

.admin-wrapper h4 {
  margin: 0;
  border-bottom: 0;
}

.admin-wrapper form > h4 {
  margin-top: 2em !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.admin-wrapper .lead {
  margin-bottom: 15px;
}

.admin-wrapper .flash-message,
.admin-wrapper .applications-list__item,
.admin-wrapper .filters-list__item {
  border-radius: var(--radius);
  border: 0;
  overflow: clip;
}

.admin-wrapper .fields-row {
  margin-inline: 0;
  border-radius: var(--radius);
  overflow: clip;
  padding-top: 0;
  gap: 2px;
  display: flex;
  flex-wrap: wrap;
}

.admin-wrapper .fields-group:not(.fields-row__column),
.admin-wrapper .fields-row {
  margin-bottom: 1em !important;
}

.admin-wrapper .fields-row > .fields-row__column {
  max-width: unset;
  width: 300px;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 !important;
}

.admin-wrapper .fields-row > .fields-row__column .fields-group {
  border-radius: 0 !important;
  margin: 0 !important;
}

.admin-wrapper .fields-row > .fields-row__column .with_block_label {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.admin-wrapper
  .fields-row
  > .fields-row__column
  .with_block_label
  > .label_input {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.admin-wrapper
  .fields-row
  > .fields-row__column
  .with_block_label
  > .label_input
  > textarea {
  min-height: 300px;
  flex-grow: 1;
}

.admin-wrapper .fields-row > .fields-row__column > :last-child {
  flex-grow: 1;
  align-items: flex-start;
  border: 0;
}

.admin-wrapper
  .fields-row
  > .fields-row__column
  > :not(:first-child, :last-child) {
  padding-block: 0.5em !important;
  margin-block: -3px;
}

.admin-wrapper :not(.fields-row__column) > .fields-group,
.admin-wrapper .fields-row > *,
.admin-wrapper .label_input > ul,
.admin-wrapper .label_input__wrapper > ul,
.admin-wrapper .with_block_label.radio_buttons .label_input {
  border-radius: var(--radius);
  overflow: hidden;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.admin-wrapper :not(.fields-row__column) > .fields-group > *,
.admin-wrapper .fields-row > * > *,
.admin-wrapper .label_input > ul > *,
.admin-wrapper .label_input__wrapper > ul > *,
.admin-wrapper .with_block_label.radio_buttons .label_input > * {
  background-color: var(--elevated-color);
  padding: 0.8rem;
  margin-block: 0;
  position: relative;
  border-radius: 0 !important;
  overflow: hidden;
}

.admin-wrapper :not(.fields-row__column) > .fields-group > *::after,
.admin-wrapper .fields-row > * > *::after,
.admin-wrapper .label_input > ul > *::after,
.admin-wrapper .label_input__wrapper > ul > *::after,
.admin-wrapper .with_block_label.radio_buttons .label_input > *::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--hover-color);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.2s;
}

.admin-wrapper :not(.fields-row__column) > .fields-group > *:hover::after,
.admin-wrapper .fields-row > * > *:hover::after,
.admin-wrapper .label_input > ul > *:hover::after,
.admin-wrapper .label_input__wrapper > ul > *:hover::after,
.admin-wrapper .with_block_label.radio_buttons .label_input > *:hover::after,
.admin-wrapper
  :not(.fields-row__column)
  > .fields-group
  > *:focus-within::after,
.admin-wrapper .fields-row > * > *:focus-within::after,
.admin-wrapper .label_input > ul > *:focus-within::after,
.admin-wrapper .label_input__wrapper > ul > *:focus-within::after,
.admin-wrapper
  .with_block_label.radio_buttons
  .label_input
  > *:focus-within::after {
  opacity: 1;
}

.admin-wrapper
  :not(.fields-row__column)
  > .fields-group
  :not(.input.with_block_label)
  > label::before,
.admin-wrapper .fields-row > * :not(.input.with_block_label) > label::before,
.admin-wrapper .label_input > ul :not(.input.with_block_label) > label::before,
.admin-wrapper
  .label_input__wrapper
  > ul
  :not(.input.with_block_label)
  > label::before,
.admin-wrapper
  .with_block_label.radio_buttons
  .label_input
  :not(.input.with_block_label)
  > label::before {
  content: '';
  position: absolute;
  inset: -900px;
}

.admin-wrapper .label_input__wrapper > :not([type='checkbox'], label) {
  margin-top: 4px;
}

.admin-wrapper .label_input {
  position: relative;
}

.admin-wrapper label {
  margin: 0 !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.admin-wrapper label input {
  margin: 0;
  margin-inline-end: 10px !important;
  position: static !important;
}

.admin-wrapper input,
.admin-wrapper .select {
  border-radius: var(--radius) !important;
  z-index: 2;
}

.admin-wrapper .radio {
  flex-grow: 1;
}

.admin-wrapper .radio:not(:last-child) {
  margin-bottom: 0 !important;
}

.admin-wrapper .hint:last-child {
  margin-bottom: 0 !important;
}

.admin-wrapper .input.with_block_label > .row {
  flex-wrap: wrap;
  margin: 0;
}

.admin-wrapper .input.with_block_label > .row > .string {
  padding: 0;
  width: 100%;
  margin: 0;
}

.admin-wrapper .input.with_block_label > .row > .string:first-child input {
  border-radius: var(--radius) var(--radius) 0 0 !important;
}

.admin-wrapper .input.with_block_label > .row > .string:last-child input {
  border-radius: 0 0 var(--radius) var(--radius) !important;
}

.admin-wrapper .input.with_block_label > .row:not(:last-child) {
  margin-bottom: 8px;
}

.admin-wrapper li.checkbox {
  flex-grow: 1;
  overflow: hidden;
}

.admin-wrapper ul {
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 2px;
  flex-grow: 1;
}

.admin-wrapper li.checkbox {
  flex-basis: 45%;
}

.admin-wrapper .spacer {
  border-top: 1px solid var(--border-color) !important;
}

.batch-table label {
  padding-inline-start: 20px !important;
}

.batch-table,
.table,
:not(.batch-table__row__content) > table {
  overflow: clip;
  border-radius: var(--radius);
  border-spacing: 0 2px;
  border-collapse: separate;
}

.batch-table__toolbar,
.batch-table__row,
.batch-table tr > *,
.table tr > *,
:not(.batch-table__row__content) > table tr > * {
  border: 0;
  margin-bottom: 2px !important;
}

.batch-table td,
.table td,
:not(.batch-table__row__content) > table td,
.batch-table th,
.table th,
:not(.batch-table__row__content) > table th,
.batch-table__row {
  position: relative;
}

.batch-table tr > td > div > span,
.table tr > td > div > span,
:not(.batch-table__row__content) > table tr > td > div > span,
.batch-table tr > th > div > span,
.table tr > th > div > span,
:not(.batch-table__row__content) > table tr > th > div > span {
  padding-inline: 0.7em;
  display: inline-block;
}

.keyboard-shortcuts {
  padding: 0;
  margin-top: -4px;
}

.keyboard-shortcuts table {
  width: 100%;
  border-radius: 0;
}

.keyboard-shortcuts td {
  padding: 0.7em;
}

.batch-table__row,
.batch-table th,
.table th,
:not(.batch-table__row__content) > table th,
.batch-table > tbody > tr > td,
.table > tbody > tr > td,
:not(.batch-table__row__content) > table > tbody > tr > td,
.batch-table tfoot td,
.table tfoot td,
:not(.batch-table__row__content) > table tfoot td {
  background: var(--elevated-color) !important;
  vertical-align: middle;
}

.batch-table__row::after,
.batch-table th::after,
.table th::after,
:not(.batch-table__row__content) > table th::after,
.batch-table > tbody > tr > td::after,
.table > tbody > tr > td::after,
:not(.batch-table__row__content) > table > tbody > tr > td::after,
.batch-table tfoot td::after,
.table tfoot td::after,
:not(.batch-table__row__content) > table tfoot td::after {
  content: '';
  position: absolute;
  inset: 0;
  background: var(--hover-color);
  opacity: 0;
  transition: 0.2s;
  pointer-events: none;
}

.batch-table__row:hover::after,
.batch-table th:hover::after,
.table th:hover::after,
:not(.batch-table__row__content) > table th:hover::after,
.batch-table > tbody > tr > td:hover::after,
.table > tbody > tr > td:hover::after,
:not(.batch-table__row__content) > table > tbody > tr > td:hover::after,
.batch-table tfoot td:hover::after,
.table tfoot td:hover::after,
:not(.batch-table__row__content) > table tfoot td:hover::after,
.batch-table__row:focus-within::after,
.batch-table th:focus-within::after,
.table th:focus-within::after,
:not(.batch-table__row__content) > table th:focus-within::after,
.batch-table > tbody > tr > td:focus-within::after,
.table > tbody > tr > td:focus-within::after,
:not(.batch-table__row__content) > table > tbody > tr > td:focus-within::after,
.batch-table tfoot td:focus-within::after,
.table tfoot td:focus-within::after,
:not(.batch-table__row__content) > table tfoot td:focus-within::after {
  opacity: 1;
}

.batch-table__row > a:first-child:last-child,
.batch-table th > a:first-child:last-child,
.table th > a:first-child:last-child,
:not(.batch-table__row__content) > table th > a:first-child:last-child,
.batch-table > tbody > tr > td > a:first-child:last-child,
.table > tbody > tr > td > a:first-child:last-child,
:not(.batch-table__row__content)
  > table
  > tbody
  > tr
  > td
  > a:first-child:last-child,
.batch-table tfoot td > a:first-child:last-child,
.table tfoot td > a:first-child:last-child,
:not(.batch-table__row__content) > table tfoot td > a:first-child:last-child {
  margin: 0;
  width: 100%;
  padding: 0.5em;
}

.batch-table th:hover td:not([rowspan])::after,
.table th:hover td:not([rowspan])::after,
:not(.batch-table__row__content) > table th:hover td:not([rowspan])::after,
.batch-table tr:hover td:not([rowspan])::after,
.table tr:hover td:not([rowspan])::after,
:not(.batch-table__row__content) > table tr:hover td:not([rowspan])::after,
.batch-table th:hover th:not([rowspan])::after,
.table th:hover th:not([rowspan])::after,
:not(.batch-table__row__content) > table th:hover th:not([rowspan])::after,
.batch-table tr:hover th:not([rowspan])::after,
.table tr:hover th:not([rowspan])::after,
:not(.batch-table__row__content) > table tr:hover th:not([rowspan])::after {
  opacity: 1 !important;
}

.batch-table th [rowspan]:hover ~ td::after,
.table th [rowspan]:hover ~ td::after,
:not(.batch-table__row__content) > table th [rowspan]:hover ~ td::after,
.batch-table tr [rowspan]:hover ~ td::after,
.table tr [rowspan]:hover ~ td::after,
:not(.batch-table__row__content) > table tr [rowspan]:hover ~ td::after {
  opacity: 0 !important;
}

.batch-table th [rowspan]::after,
.table th [rowspan]::after,
:not(.batch-table__row__content) > table th [rowspan]::after,
.batch-table tr [rowspan]::after,
.table tr [rowspan]::after,
:not(.batch-table__row__content) > table tr [rowspan]::after {
  inset-inline: -900px;
}

.layout-multiple-columns.layout-multiple-columns {
  --column-header-height: 45px;
}

.layout-multiple-columns.layout-multiple-columns .column-header,
.layout-multiple-columns.layout-multiple-columns .column-header button {
  background: none;
}

.layout-multiple-columns.layout-multiple-columns .column-header,
.layout-multiple-columns.layout-multiple-columns .scrollable,
.layout-multiple-columns.layout-multiple-columns .column-back-button,
.layout-multiple-columns.layout-multiple-columns .account__header__image {
  border-radius: 0 !important;
  gap: 0 !important;
}

.layout-multiple-columns.layout-multiple-columns .columns-area {
  background: none !important;
  height: 100%;
}

.layout-multiple-columns.layout-multiple-columns .columns-area > div {
  border: 0 !important;
  padding: 0 !important;
}

.layout-multiple-columns.layout-multiple-columns
  .columns-area
  > div:not(.drawer, :last-child) {
  margin-inline-end: 2px !important;
}

.layout-multiple-columns.layout-multiple-columns .columns-area > div.column {
  flex-grow: 1;
  max-width: 600px;
}

.layout-multiple-columns.layout-multiple-columns
  .columns-area
  > div:first-child {
  margin-inline-start: auto !important;
}

.layout-multiple-columns.layout-multiple-columns
  .columns-area
  > div:last-child {
  margin-inline-end: auto !important;
}

.layout-multiple-columns.layout-multiple-columns .drawer.drawer {
  padding-top: 15px !important;
  overflow: clip;
  flex-grow: 1;
  max-width: 350px;
}

.drawer__header {
  border-radius: var(--radius-round);
  background: var(--elevated-color);
  margin-inline: 15px;
  overflow: hidden;
  border: 0 !important;
}

.drawer__header a {
  border: 0;
}

.drawer__header a:first-child {
  padding-inline-start: 15px !important;
}

.drawer__header a:last-child {
  padding-inline-end: 15px !important;
}

.layout-multiple-columns.layout-multiple-columns .drawer.drawer .search {
  z-index: 2;
  margin-inline: 15px;
  margin-bottom: 0;
}

.layout-multiple-columns.layout-multiple-columns
  .drawer.drawer
  > .drawer__pager {
  border: 0;
  overflow: visible !important;
}

.layout-multiple-columns.layout-multiple-columns
  .drawer.drawer
  .drawer__inner:not(.darker) {
  margin-top: -20px;
  padding-top: 30px;
  height: unset;
  bottom: 0;
}

.layout-multiple-columns.layout-multiple-columns
  .drawer.drawer
  .drawer__inner__mastodon {
  margin-inline: -6px;
  z-index: -1;
}

.layout-multiple-columns.layout-multiple-columns .compose-form {
  margin-inline: 5px;
}

.layout-multiple-columns.layout-multiple-columns .drawer__inner:not(.darker),
.layout-multiple-columns.layout-multiple-columns .drawer__inner__mastodon {
  background-color: transparent !important;
}

.layout-multiple-columns.layout-multiple-columns .darker {
  background-color: var(--surface-background-color);
  border-radius: var(--radius) var(--radius) 0 0;
  top: 10px;
  width: unset;
  inset-inline: 2px;
}

.layout-multiple-columns.layout-multiple-columns .column {
  background: none;
}

.layout-multiple-columns.layout-multiple-columns .column::after {
  content: '';
  position: absolute;
  inset: 0;
  top: var(--column-header-height);
  background: var(--background-color);
  z-index: -1;
}

.layout-multiple-columns.layout-multiple-columns .column::before,
.layout-multiple-columns.layout-multiple-columns .column::after {
  top: var(--column-header-height);
  border-radius: var(--radius) var(--radius) 0 0;
}

.layout-multiple-columns.layout-multiple-columns .column-back-button.active,
.layout-multiple-columns.layout-multiple-columns
  .column-header__wrapper.active {
  box-shadow: none;
}

.layout-multiple-columns.layout-multiple-columns
  .column-back-button.active::before,
.layout-multiple-columns.layout-multiple-columns
  .column-header__wrapper.active::before {
  inset-inline: var(--radius);
}

.layout-multiple-columns.layout-multiple-columns
  .column-back-button
  .column-header,
.layout-multiple-columns.layout-multiple-columns
  .column-header__wrapper
  .column-header {
  border: 0 !important;
  height: var(--column-header-height);
}

.layout-multiple-columns.layout-multiple-columns
  .column-back-button
  .column-header__buttons,
.layout-multiple-columns.layout-multiple-columns
  .column-header__wrapper
  .column-header__buttons {
  height: 100%;
}

.layout-multiple-columns.layout-multiple-columns .column-back-button svg,
.layout-multiple-columns.layout-multiple-columns .column-header__wrapper svg {
  height: 1.4em;
}

.layout-multiple-columns.layout-multiple-columns
  .column-back-button
  + .scrollable.scrollable,
.layout-multiple-columns.layout-multiple-columns
  .column-header__wrapper
  + .scrollable.scrollable {
  border-radius: var(--radius) var(--radius) 0 0 !important;
  overflow-y: scroll;
}

.layout-multiple-columns.layout-multiple-columns .getting-started__trends {
  padding: 0 20px;
}

.column[aria-labelledby='Misc'] > .scrollable,
.column[aria-labelledby='Getting-started'] > .scrollable,
.getting-started {
  position: relative;
  padding: 5px 10px !important;
}

.column[aria-labelledby='Misc'] > .scrollable .getting-started__wrapper,
.column[aria-labelledby='Getting-started']
  > .scrollable
  .getting-started__wrapper,
.getting-started .getting-started__wrapper {
  background: none;
}

.column[aria-labelledby='Misc'] > .scrollable .column-link,
.column[aria-labelledby='Getting-started'] > .scrollable .column-link,
.getting-started .column-link,
.column[aria-labelledby='Misc'] > .scrollable .column-subheading,
.column[aria-labelledby='Getting-started'] > .scrollable .column-subheading,
.getting-started .column-subheading {
  border: 0 !important;
  padding: 20px !important;
  background: none;
}

.column[aria-labelledby='Misc'] > .scrollable .getting-started__footer,
.column[aria-labelledby='Getting-started']
  > .scrollable
  .getting-started__footer,
.getting-started .getting-started__footer {
  padding-inline: 20px;
}

.column[aria-labelledby='Misc'] > .scrollable .getting-started__footer a span,
.column[aria-labelledby='Getting-started']
  > .scrollable
  .getting-started__footer
  a
  span,
.getting-started .getting-started__footer a span {
  font-size: 1.1em !important;
  line-height: 2;
}
@media (width >= 890px) and (width <= 1175px) {
  .ui__header {
    background: none !important;
    backdrop-filter: none;
    position: relative;
    margin-inline-end: 285px;
    border: 0 !important;
  }

  .navigation-panel {
    padding-inline: 10px;
  }
}
@media (width <= 889px) {
  .ui__header {
    border-bottom: 0;
    height: auto;
    position: relative;
    box-sizing: border-box;
    gap: 5px 10px;
    flex-wrap: wrap;
    padding-block: 12px;
    border: 0 !important;
    backdrop-filter: none !important;
    background: none !important;
  }

  .ui__header .ui__header__logo {
    padding-block: 5px !important;
  }

  .ui__header .ui__header__links {
    min-width: max-content;
    position: sticky;
    inset-inline-end: 55px;
    background: inherit;
  }

  :has(body) .ui__header .ui__header__links {
    right: 0;
  }

  .ui__header [href='/search'] {
    display: none;
  }

  .ui__header [href='/publish'] {
    position: fixed;
    bottom: 15px;
    inset-inline-end: 15px;
    border-radius: 100px !important;
    border-radius: var(--radius) !important;
    height: 60px;
    width: 60px;
    font-size: 0;
    box-shadow: var(--shadow-low);
    background: var(--surface-background-color);
    border-color: var(--border-color);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    touch-action: manipulation;
    animation: scale-in 0.2s cubic-bezier(0, 0, 0, 1.1);
    visibility: visible;
  }

  .ui__header [href='/publish']::before {
    content: '';
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    transition: transform 0.4s cubic-bezier(0, 0, 0, 1) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAtOTYwIDk2MCA5NjAiIHdpZHRoPSIyNHB4IiBmaWxsPSIjZThlYWVkIj48cGF0aCBkPSJNMjAwLTEyMHEtMzMgMC01Ni41LTIzLjVUMTIwLTIwMHYtNTYwcTAtMzMgMjMuNS01Ni41VDIwMC04NDBoMzU3bC04MCA4MEgyMDB2NTYwaDU2MHYtMjc4bDgwLTgwdjM1OHEwIDMzLTIzLjUgNTYuNVQ3NjAtMTIwSDIwMFptMjgwLTM2MFpNMzYwLTM2MHYtMTcwbDM2Ny0zNjdxMTItMTIgMjctMTh0MzAtNnExNiAwIDMwLjUgNnQyNi41IDE4bDU2IDU3cTExIDEyIDE3IDI2LjV0NiAyOS41cTAgMTUtNS41IDI5LjVUODk3LTcyOEw1MzAtMzYwSDM2MFptNDgxLTQyNC01Ni01NiA1NiA1NlpNNDQwLTQ0MGg1NmwyMzItMjMyLTI4LTI4LTI5LTI4LTIzMSAyMzF2NTdabTI2MC0yNjAtMjktMjggMjkgMjggMjggMjgtMjgtMjhaIi8+PC9zdmc+');
  }

  body:not(:has(.column-link.active))
    .ui__header
    [href='/publish']:not(:focus) {
    transform: scale(0.97) !important;
    opacity: 0 !important;
    pointer-events: none;
  }

  .ui__header [href^='/@'],
  .ui__header a.permalink[href] {
    position: fixed;
    inset-inline-end: 1px;
    width: 53px;
    display: flex;
    justify-content: center;
    background: inherit;
  }

  :has(body) .ui__header [href^='/@'],
  :has(body) .ui__header a.permalink[href] {
    position: static !important;
    width: unset;
  }

  .tabs-bar__wrapper {
    top: 0;
    z-index: 200;
  }

  .tabs-bar__wrapper::after {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--elevated-tint);
    pointer-events: none;
    z-index: 2;
  }
  @supports selector(.foxxo:has(.waf)) {
    [href='/publish'] {
      bottom: 70px !important;
    }

    .columns-area__panels {
      flex-direction: column;
    }

    .columns-area__panels__main {
      width: 100%;
      border-radius: var(--radius) !important;
      margin: 0 !important;
      border: 0 !important;
      overflow: clip !important;
      flex-grow: 1;
    }

    .tabs-bar__wrapper {
      transition: none !important;
    }

    .columns-area__panels__pane--navigational {
      display: contents;
    }

    .columns-area__panels__pane--navigational
      .columns-area__panels__pane__inner {
      position: static !important;
      order: -1;
      width: unset;
      height: auto;
      white-space: nowrap;
    }

    .columns-area__panels__pane--navigational .navigation-panel {
      flex-direction: row;
      margin: 0;
      padding: 12px;
      border-top: 1px solid;
      align-items: center;
      overflow: visible;
      height: auto;
    }

    .columns-area__panels__pane--navigational .navigation-panel .flex-spacer {
      display: none;
    }

    .columns-area__panels__pane--navigational hr {
      display: none;
    }

    .columns-area__panels__pane--navigational .trends__item__name > span,
    .columns-area__panels__pane--navigational .trends__item__sparkline {
      display: none;
    }

    .columns-area__panels__pane--navigational h4 a span::after {
      content: ':' !important;
    }

    .columns-area__panels__pane--navigational .navigation-panel__legal {
      display: contents;
    }

    .columns-area__panels__pane--navigational a {
      position: relative;
      overflow: hidden;
      border-radius: var(--radius);
      font-weight: 600;
      font-size: 1.1em;
      flex-grow: 1;
      text-align: center;
      min-width: max-content;
      justify-content: center;
      transition: padding 0.2s;
      padding-inline: 6px;
      gap: 6px;
    }

    .columns-area__panels__pane--navigational a::before {
      content: '';
      position: absolute;
      inset: 0;
      background: currentcolor;
      opacity: 0;
      border-radius: inherit;
      transition:
        inset 0.4s cubic-bezier(0, 0, 0, 1.2),
        opacity 0.2s;
    }

    .columns-area__panels__pane--navigational a svg {
      width: 1em;
      height: 1em;
      margin-inline-end: 0 !important;
    }

    .columns-area__panels__pane--navigational a.active {
      position: relative;
    }

    .columns-area__panels__pane--navigational a.active::before {
      inset: 0 !important;
      opacity: 0.1;
    }

    .columns-area__panels__pane--navigational span {
      display: unset !important;
      width: 0;
      flex-grow: 1;
      max-width: max-content;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  :not(:has(.navigation-panel__sign-in-banner)) .columns-area__panels__main {
    margin-top: 2px !important;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .columns-area--mobile .column {
    padding-bottom: 60px;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .ui__header {
    z-index: 199;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .ui__header::before,
  :not(:has(.navigation-panel__sign-in-banner)) .ui__header::after {
    all: unset;
    content: '';
    position: fixed;
    bottom: 0;
    inset-inline: 0;
    background: var(--background-color);
    height: 60px;
    visibility: visible;
    z-index: -1;
    border-top: 1px solid var(--background-border-color);
  }

  :not(:has(.navigation-panel__sign-in-banner)) .ui__header::after {
    background: var(--elevated-tint);
    border: 0;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .navigation-panel {
    flex-wrap: wrap;
    border-top: none;
    margin-bottom: 8px;
    padding-block: 0;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .navigation-panel a span {
    display: none !important;
  }

  :not(:has(.navigation-panel__sign-in-banner))
    .navigation-panel
    [href='/settings/preferences'] {
    position: absolute;
    top: 12px;
    inset-inline-end: 60px;
    z-index: 900;
    padding-block: 0;
    height: 35px;
    width: 35px;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid var(--border-color);
  }

  :root {
    --selector: '.column-link[href=' / home '],.column-link[href=' /
      notifications '],.column-link[href=' / explore '],.column-link[href=' /
      public/local '],.column-link[href=' / lists ']';
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/home'],
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/notifications'],
  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/explore'],
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/public/local'],
  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/lists'] {
    position: fixed !important;
    display: flex;
    flex-direction: column;
    bottom: 4px;
    z-index: 201;
    font-size: 1.7em;
    margin: 0 !important;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 19%;
    left: 0;
    box-sizing: border-box;
    padding: 1px !important;
  }

  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/home']::before,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/notifications']::before,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/explore']::before,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/public/local']::before,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/lists']::before {
    inset-inline: 6px;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/home'] span,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/notifications']
    span,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/explore']
    span,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/public/local']
    span,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/lists']
    span {
    margin: 0;
    font-size: 0;
    line-height: 1;
    opacity: 0;
    transition:
      font-size 0.4s,
      margin 0.7s,
      opacity 0.2s;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/home'] svg,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/notifications']
    svg,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/explore']
    svg,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/public/local']
    svg,
  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/lists']
    svg {
    width: 24px;
    height: 24px;
    transition: transform 0.1s;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/home'] {
    left: 2%;
  }

  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/notifications'] {
    left: 21.25%;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/explore'] {
    left: 40.5%;
  }

  :not(:has(.navigation-panel__sign-in-banner))
    .column-link[href='/public/local'] {
    left: 59.75%;
  }

  :not(:has(.navigation-panel__sign-in-banner)) .column-link[href='/lists'] {
    left: 79%;
  }
}

#hover-card,
.dropdown-menu {
  border-radius: var(--radius);
  animation: scale-in 0.2s cubic-bezier(0, 0, 0, 1.1);
}

.dropdown-menu__container__list {
  overflow: hidden auto;
  border-radius: var(--radius);
  max-height: 70vh;
}

.dropdown-menu__item {
  overflow: hidden;
}

.dropdown-menu__item a {
  padding: 0.7em 1em !important;
  transition:
    background-color 0.2s,
    color 0.2s;
  min-width: 150px;
}

.dropdown-menu__separator {
  margin: 0 !important;
}

.interaction-modal {
  border-radius: var(--radius);
  overflow-y: auto;
  box-sizing: border-box;
  width: 700px;
  text-align: center;
}

.interaction-modal__choices {
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
}

.interaction-modal__choices .interaction-modal__choices__choice {
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  padding: 24px;
  margin: 0;
  border-radius: var(--radius);
  transition: background 0.2s;
  position: relative;
}

.interaction-modal__choices .prose:last-child {
  margin-bottom: 0;
}

.interaction-modal__choices h3 {
  margin-bottom: 10px;
}

.modal-root__container {
  animation: bounce-in 0.7s;
}
@media (width <= 890px) {
  .modal-root__modal {
    margin-top: auto;
    max-width: 100%;
    border-radius: var(--radius) var(--radius) 0 0;
  }
}

.picture-in-picture {
  z-index: 101;
}

.picture-in-picture .picture-in-picture__header {
  border-radius: var(--radius) var(--radius) 0 0;
}

.picture-in-picture .media-gallery,
.picture-in-picture .video-player,
.picture-in-picture .status-card.horizontal.interactive,
.picture-in-picture .status-card,
.picture-in-picture .audio-player,
.picture-in-picture .picture-in-picture-placeholder {
  --radius: 0;

  margin: 0 !important;
}

.picture-in-picture .picture-in-picture__footer {
  border-radius: 0 0 var(--radius) var(--radius);
}

.report-modal[style='max-width: 960px;'] {
  background: var(--background-color);
}

.report-modal[style='max-width: 960px;'],
.report-modal[style='max-width: 960px;'] * {
  color: inherit !important;
}

.report-modal[style='max-width: 960px;'] .report-modal__comment {
  min-width: unset;
  width: 370px;
  max-width: unset;
  flex: none;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.report-modal[style='max-width: 960px;'] .setting-text__wrapper {
  border-radius: var(--radius);
  overflow: hidden;
  background: none;
}

.report-modal[style='max-width: 960px;'] .setting-text__wrapper textarea {
  border: 0;
  max-height: unset !important;
  background: none;
}

.report-modal[style='max-width: 960px;'] .focal-point-modal__content {
  position: sticky;
  top: 0;
  max-height: 100vh;
  flex-grow: 0 !important;
  max-width: 100%;
}

.report-modal[style='max-width: 960px;'] .focal-point {
  width: 100%;
  height: 100%;
}

.report-modal[style='max-width: 960px;'] .audio-player,
.report-modal[style='max-width: 960px;'] .focal-point img {
  width: unset !important;
  height: unset !important;
  max-height: 100% !important;
  max-width: 100% !important;
}

.report-modal[style='max-width: 960px;'] .audio-player {
  margin: 10px !important;
  width: 600px !important;
  max-width: calc(100% - 20px) !important;
}

.report-modal[style='max-width: 960px;'] .focal-point__reticle {
  box-shadow: 0 0 300px 200px rgba(0, 0, 0, 20%);
}
@media not all and (width <= 900px) {
  .report-modal[style='max-width: 960px;'] {
    max-width: max-content !important;
    max-height: 98vh;
    border: 0;
    box-shadow: var(--shadow);
    overflow: hidden;
    border-radius: var(--radius);
    width: 98vw;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__container {
    flex-wrap: nowrap;
    border: 0;
    max-width: max-content;
    max-height: 100%;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__target {
    position: absolute;
    padding: 24px 20px 12px;
    font-weight: bold;
    width: 348px;
    box-sizing: border-box;
    text-align: start;
    background: inherit;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__close {
    position: fixed !important;
    right: 12px;
    top: 12px;
    order: 2;
    color: #fff;
    background: var(--modal-background-color);
    padding: 12px;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__comment {
    padding-top: calc(30px + 2em);
    padding-bottom: 160px;
  }

  .report-modal[style='max-width: 960px;'] .focal-point-modal__content,
  .report-modal[style='max-width: 960px;'] .focal-point {
    overflow: visible;
  }

  .report-modal[style='max-width: 960px;'] .focal-point img {
    min-width: 500px;
  }

  .report-modal[style='max-width: 960px;'] .focal-point__preview {
    inset-inline-start: -220px;
    right: unset;
    bottom: 20px;
    pointer-events: none;
    text-align: end;
  }

  .report-modal[style='max-width: 960px;'] .focal-point__preview strong {
    color: inherit;
  }

  .report-modal[style='max-width: 960px;'] .focal-point__preview div {
    border-radius: var(--radius);
    box-shadow: none;
  }
}
@media (width <= 900px) {
  .report-modal[style='max-width: 960px;'] {
    height: auto;
    width: 100vw;
    max-width: unset !important;
    border-radius: 0;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__container {
    height: auto;
    min-height: 0;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__container {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    flex-grow: 1;
  }

  .report-modal[style='max-width: 960px;'] .report-modal__comment {
    width: 100%;
    border: 0;
    max-height: 70%;
    flex: 0 0 auto;
    height: unset;
    order: unset;
  }
}

.emoji-picker-dropdown__menu {
  border-radius: var(--radius);
  overflow: hidden;
  resize: both;
  width: 400px;
}

.emoji-mart {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  height: 100% !important;
}

.emoji-mart-scroll {
  flex-grow: 1;
  max-height: unset !important;
}

.emoji-mart-bar {
  order: 2;
}

.emoji-mart-category-list {
  overflow: visible !important;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(20px + 6%), 1fr));
}

.emoji-mart-category-list li {
  display: contents;
}

.emoji-mart-category-list button {
  position: relative;
  padding: 0 !important;
  padding-top: 100% !important;
}

.emoji-mart-category-list button img,
.emoji-mart-category-list button span {
  height: calc(100% - 10px) !important;
  width: calc(100% - 10px) !important;
  inset: 5px;
  position: absolute !important;
  transition: transform 0.1s cubic-bezier(0, 0, 0, 1);
}

.emoji-mart-category-list button:hover img,
.emoji-mart-category-list button:hover span {
  transform: scale(1.2);
}

.emoji-picker-dropdown__modifiers {
  top: 16px;
}
